import { StoreModules } from "@/store";
import { Module } from "vuex";

export interface Layout {
  minimal: boolean;
  showLayoutHeader: boolean;
  showNavigation: boolean;
  currentBrowserWidth: number;
}

const SAVED_MINIMAL_STATE: string = "savedMinimalState";

export enum Breakpoints {
  xs = 0,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1480,
}

export const LAYOUT_ACTIONS = {
  setMinimal: "layout/setMinimal",
  toggleMinimal: "layout/toggleMinimal",
};

export const LAYOUT_MUTATIONS = {
  setLayoutHeaderVisibility: "layout/setLayoutHeaderVisibility",
  setNavigationVisibility: "layout/setNavigationVisibility",
  setCurrentBrowserWidth: "layout/setCurrentBrowserWidth",
};

const layout: Module<Layout, StoreModules> = {
  namespaced: true,
  state: {
    minimal: localStorage.getItem(SAVED_MINIMAL_STATE) === "true",
    showLayoutHeader: true,
    showNavigation: false,
    currentBrowserWidth: 0,
  },
  getters: {
    responsiveAtLeast: (state) => (breakpoint: Breakpoints) => {
      return state.currentBrowserWidth >= breakpoint;
    },

    responsiveUpTo: (state) => (breakpoint: Breakpoints) => {
      return state.currentBrowserWidth <= breakpoint - 1;
    },

    minimal: (state: Layout): boolean => {
      return state.minimal && state.currentBrowserWidth < 1330 + 276 * 2;
    },
  },
  actions: {
    setMinimal({ state, commit }, minimal: boolean) {
      commit("setMinimal", minimal);
    },
    toggleMinimal({ state, commit }) {
      commit("setMinimal", !state.minimal);
    },
  },
  mutations: {
    setMinimal(state, minimal: boolean) {
      state.minimal = minimal;
      localStorage.setItem(SAVED_MINIMAL_STATE, minimal.toString());
    },
    setLayoutHeaderVisibility(state, value: boolean) {
      state.showLayoutHeader = value;
    },
    setNavigationVisibility(state, value: boolean) {
      state.showNavigation = value;
    },
    setCurrentBrowserWidth(state, value: number) {
      state.currentBrowserWidth = value;
    },
  },
};

export default layout;
