import { StoreModules } from "@/store";
import { Module } from "vuex";
import { DocumentParameters, tasksApi } from "@/api/portal/tasks";
import { find } from "lodash";
import { Department, Document, DocumentType } from "@/models/document";
import { DocumentDiscipline } from "@/enums/DocumentDiscipline";
import { taskReadApi } from "@/api/portal/task-read-api";

export interface Documents {
  loading: boolean;
  documents: Document[];
}

export interface NewDocument {
  title: string;
  accountId: string;
  department: Department;
  documentType: DocumentType | null;
  discipline: DocumentDiscipline | null;
  period: string;
  endDate: string | null;
  signer: string;
  sender: string;
  editor: string;
  vatFiscalEntityDivision: string;
  nexusRecipient: string;
  files?: any[];
}

const documents: Module<Documents, StoreModules> = {
  namespaced: true,
  state: {
    loading: true,
    documents: [],
  },
  mutations: {
    setDocuments(state: Documents, documents: Document[]) {
      state.documents = documents;
    },
    setLoading: (state: Documents, loading: boolean) => {
      state.loading = loading;
    },
  },
  getters: {
    hasDocuments: (state): boolean => {
      return state.documents.length > 0;
    },
    hasDocument:
      (state: Documents, getters) =>
      (documentId: string): boolean => {
        return !!getters.getDocument(documentId);
      },
    getDocument:
      (state: Documents) =>
      (documentId: string): Document | undefined => {
        return find(state.documents, { id: documentId });
      },
  },
  actions: {
    async getDocuments(
      { state, commit },
      params?: DocumentParameters
    ): Promise<any> {
      commit("setLoading", true);
      return taskReadApi
        .tasks(params)
        .then((documents) => {
          commit("setDocuments", documents.data);
        })
        .catch((error) => {})
        .finally(() => {
          commit("setLoading", false);
        });
    },
    addNewDocument({ state, commit }, document: NewDocument): Promise<any> {
      return new Promise((resolve, reject) => {
        tasksApi
          .addDocument(document)
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};

export const DOCUMENTS_ACTIONS = {
  addNewDocument: "documents/addNewDocument",
};

export default documents;
