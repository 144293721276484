import { Role } from "@/models/role";
import { KeycloakAttributes } from "@/lib/keycloak/KeycloakPlugin";

export const keycloakRoles = ["ROLE_USER", "ROLE_EMPLOYEE"] as const;
export type KeyCloakRole = typeof keycloakRoles[number];

export const extractRole = (roles: string[]): KeyCloakRole => {
  const extractedRoles = (roles as KeyCloakRole[]).filter((item) =>
    keycloakRoles.includes(item)
  );
  if (extractedRoles.length !== 1) {
    throw new Error("The user requires exactly one portal role");
  }
  return extractedRoles[0];
};

export const extractUserId = (
  role: Role,
  attributes: Record<string, string>
): string => {
  const mapper: Record<Role, string> = {
    customer: attributes.naturalpersonid?.[0] ?? "",
    employee: attributes.oid?.[0] ?? "",
  };
  return mapper[role];
};
