import _Vue from "vue";

import Keycloak, {
  KeycloakInitOptions,
  KeycloakLoginOptions,
  KeycloakLogoutOptions,
  KeycloakProfile,
  KeycloakTokenParsed,
} from "keycloak-js";
import {
  KeycloakInitConfig,
  KeycloakWrapperInstance,
} from "@/lib/keycloak/KeycloakPluginTypes";
import { Store } from "vuex";
import { StoreModules } from "@/store";
import { AccountPermissionError, ProfileState } from "@/store/modules/user";

export function KeycloakPlugin<KeycloakWrapperInstance>(
  Vue: typeof _Vue,
  options: KeycloakInitConfig
): void {
  Vue.prototype.$keycloak = new KeycloakWrapper(Vue, options);
}

class ProfileNotLoadedError extends Error {}

export interface KeycloakAttributes {
  oid?: string[];
  naturalpersonid?: string[];
}

export interface KeycloakProfileWithAttributes extends KeycloakProfile {
  attributes: Record<string, string>;
}

export class KeycloakWrapper implements KeycloakWrapperInstance {
  readonly vueInstance: typeof _Vue;
  readonly keycloak: Keycloak;

  initialized = false;

  constructor(vueInstance: typeof _Vue, keyCloakOptions: KeycloakInitConfig) {
    // @ts-ignore
    this.keycloak = new Keycloak(keyCloakOptions.keycloakConfig);
    this.vueInstance = vueInstance;

    this.registerCallbacks(
      this.keycloak,
      keyCloakOptions.store,
      this.vueInstance
    );
  }

  init = (
    keycloakInitOptions: KeycloakInitOptions,
    store: Store<StoreModules>
  ): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
      if (this.initialized) {
        return resolve(true);
      }

      keycloakInitOptions.token =
        sessionStorage.getItem("accessToken") || undefined;
      keycloakInitOptions.refreshToken =
        sessionStorage.getItem("refreshToken") || undefined;

      return this.keycloak
        .init(keycloakInitOptions)
        .then((state) => {
          this.initialized = state;

          if (!this.keycloak.authenticated) {
            store.dispatch("user/defineAbilities", "loaded" as ProfileState);
          }

          return resolve(state);
        })
        .catch((e) => {
          this.initialized = false;

          return reject(e);
        });
    });

  registerCallbacks = (
    instance: Keycloak,
    store: Store<StoreModules>,
    vueInstance: typeof _Vue
  ): Keycloak => {
    instance.onAuthSuccess = async () => {
      await this.authSuccess(store, vueInstance);
    };
    instance.onAuthLogout = () => {
      return this.authLogout(store);
    };
    instance.onAuthRefreshSuccess = () => {
      this.storeToken(this.keycloak.token, this.keycloak.refreshToken);
      return store.dispatch("user/updateToken", {
        token: this.keycloak.token,
        refreshToken: this.keycloak.refreshToken,
      });
    };
    instance.onTokenExpired = () => {
      this.keycloak.updateToken(60);
    };
    return instance;
  };

  authLogout = (store: Store<StoreModules>) => {
    this.initialized = false;
    store.dispatch("user/updateToken", { token: null, refreshToken: null });
    sessionStorage.clear();
    store.commit("user/resetProfile");
    store.dispatch("user/defineAbilities", "unloaded" as ProfileState);
  };

  authSuccess = (store: Store<StoreModules>, vueInstance: typeof _Vue) => {
    return this.keycloak
      .updateToken(60)
      .then((success) => {
        this.storeToken(this.keycloak.token, this.keycloak.refreshToken);
      })
      .then(() => {
        return store.dispatch("user/updateToken", {
          token: this.keycloak.token,
          refreshToken: this.keycloak.refreshToken,
        });
      })
      .then(() => {
        return this.loadUserProfile()
          .then((profile: KeycloakProfileWithAttributes) => profile)
          .catch(() => {
            throw new ProfileNotLoadedError();
          });
      })
      .then(async (data: KeycloakProfileWithAttributes) => {
        await store.dispatch("user/setProfile", {
          roles: this.keycloak.tokenParsed?.realm_access?.roles,
          username: data.username,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          attributes: data.attributes,
        });
      })
      .then(async () => await store.dispatch("user/getAccountPermissions"))
      .then(() =>
        store.dispatch("user/defineAbilities", "loaded" as ProfileState)
      )
      .catch((error) => {
        if (error instanceof ProfileNotLoadedError) {
          vueInstance.prototype.$snotify.error(
            "Er is iets misgegaan bij het laden van uw account, probeer opnieuw in te loggen of neem contact op met uw contactpersoon",
            { timeout: 4000 }
          );
          store.dispatch(
            "user/defineAbilities",
            "errorCritical" as ProfileState
          );
        } else if (error instanceof AccountPermissionError) {
          //send notification
          vueInstance.prototype.$snotify.error(
            "Er is iets misgegaan bij het laden van uw rechten, je kunt de applicatie blijven gebruiken, log opnieuw in als je denkt de applicatie niet volledig te kunnen gebruiken",
            { timeout: 50000, bodyMaxLength: 200 }
          );
          store.dispatch(
            "user/defineAbilities",
            "loadedWithErrors" as ProfileState
          );
        }
      });
  };

  storeToken = (
    token: string | undefined,
    refreshToken: string | undefined
  ): void => {
    token && sessionStorage.setItem("accessToken", token);
    refreshToken && sessionStorage.setItem("refreshToken", refreshToken);
  };

  logout = (options?: any): Promise<void> => {
    this.keycloak.clearToken();
    return this.keycloak.logout(options);
  };

  createLoginUrl = (options?: KeycloakLoginOptions): string =>
    this.keycloak.createLoginUrl(options);

  createLogoutUrl = (options?: KeycloakLogoutOptions): string =>
    this.keycloak.createLogoutUrl(options);

  loadUserProfile = (): Promise<KeycloakProfileWithAttributes> =>
    this.keycloak.loadUserProfile() as Promise<KeycloakProfileWithAttributes>;

  authenticated = (): boolean => this.keycloak.authenticated || false;

  isTokenExpired = (): boolean => this.keycloak.isTokenExpired(5);

  token = (): string => `${this.keycloak.token}`;

  tokenParsed = (): KeycloakTokenParsed | undefined =>
    this.keycloak.tokenParsed;
}
