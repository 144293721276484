import { Vue } from "vue-property-decorator";
import {
  EventSourceMessage,
  EventStreamContentType,
  fetchEventSource,
} from "@microsoft/fetch-event-source";

class UnauthorizedError extends Error {}
class RetriableError extends Error {}
class FatalError extends Error {}

interface Callbacks {
  onOpen: (event: Response) => Promise<void>;
  onMessage: (event: EventSourceMessage) => Promise<void>;
  onError: (event: any) => void;
  onClose: () => Promise<void>;
}

export class SseEventSource {
  private ctrl: AbortController;
  private readonly cb: Partial<Callbacks>;

  constructor(
    readonly url: string,
    readonly callbacks: Partial<Callbacks> = {}
  ) {
    this.ctrl = new AbortController();
    this.cb = callbacks;
  }

  public connect(): Promise<void> {
    return fetchEventSource(this.url, {
      method: "GET",
      headers: {
        Authorization: this.bearerToken,
      },
      signal: this.ctrl.signal,
      onmessage: this.cb.onMessage,
      onopen: async (response: Response) => {
        if (
          response.ok &&
          response.headers.get("content-type") === EventStreamContentType
        ) {
          return;
        } else if (response.status == 401) {
          throw new UnauthorizedError();
        } else if (
          response.status >= 400 &&
          response.status < 500 &&
          response.status !== 429
        ) {
          throw new FatalError();
        } else {
          throw new RetriableError();
        }
      },
      onerror: (err) => {
        if (err instanceof UnauthorizedError || RetriableError) {
          throw err; // rethrow to stop the operation
        }
      },
      onclose: () => {
        // if the server closes the connection unexpectedly, retry:
        throw new RetriableError();
      },
      openWhenHidden: true,
    }).catch((e: UnauthorizedError | RetriableError) => {
      this.reconnect();
    });
  }

  private get bearerToken(): string {
    return `Bearer ${Vue.prototype.$keycloak.token()}`;
  }

  public close() {
    this.ctrl.abort();
  }

  public reconnect() {
    this.close();
    this.connect();
  }
}
